import React from "react";
import { connect } from 'react-redux';

import './CardToSingle.css';
import LinkItem from "./LinkItem/LinkItem";

const CardToSingle = props => {
    if (props.lang === 'es') {
        return (
            <React.Fragment>
                <div className="rcardts">
                    <img src={props.image} alt={props.title}></img>
                    <div className="rcardts_texto">
                        <h2>{props.title}</h2>
                        <div className="rcardts_resumen">
                            <div className="rcardts_resumen_date">
                                <p>{props.date.day} de {props.date.month} de {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="rcardts_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
    if (props.lang === 'en') {
        return (
            <React.Fragment>
                <div className="rcardts">
                    <img src={props.image} alt={props.title}></img>
                    <div className="rcardts_texto">
                        <h2>{props.title}</h2>
                        <div className="rcardts_resumen">
                            <div className="rcardts_resumen_date">
                                <p>{props.date.day} {props.date.month}, {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="rcardts_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
    if (props.lang === 'fr') {
        return (
            <React.Fragment>
                <div className="rcardts">
                    <img src={props.image} alt={props.title}></img>
                    <div className="rcardts_texto">
                        <h2>{props.title}</h2>
                        <div className="rcardts_resumen">
                            <div className="rcardts_resumen_date">
                                <p>{props.date.day} {props.date.month}, {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="rcardts_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(CardToSingle);