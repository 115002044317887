import React, { useState } from "react";
import { connect } from 'react-redux';

import RoamingActivitiesList from './components/RoamingActivitiesList/RoamingActivitiesList';
import ActivitiesContent from '../../../../../content/Activities.json';
import LoadMore from './components/LoadMore/LoadMore';

let ActivitiesFinal_es = ActivitiesContent.activities_es.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
let ActivitiesFinal_en = ActivitiesContent.activities_en.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
let ActivitiesFinal_fr = ActivitiesContent.activities_fr.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});

const RoamingActivities = props => {
    const [number, setNumber] = useState(4);

    const filtrado_es = ActivitiesFinal_es.filter(activitie => props.filter === activitie.attach);
    const filtrado_en = ActivitiesFinal_en.filter(activitie => props.filter === activitie.attach);
    const filtrado_fr = ActivitiesFinal_fr.filter(activitie => props.filter === activitie.attach);

    const datos_es = filtrado_es.slice(0, number);
    const datos_en = filtrado_en.slice(0, number);
    const datos_fr = filtrado_fr.slice(0, number);

    const [cargarStyle, setCargarStyle] = useState("block");
    const [controlStyle, setControlStyle] = useState("a");

    if (filtrado_es.length <= number && controlStyle === "a") {
        setCargarStyle("none");
        setControlStyle("b");
    }
    function cargarmas() {
        setNumber(number + 4)
    };

    if (props.lang === 'es') {
        return (
            <div>
                <RoamingActivitiesList data={datos_es} lang="es" />
                <LoadMore lang="es" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div>
                <RoamingActivitiesList data={datos_en} lang="en" />
                <LoadMore lang="en" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div>
                <RoamingActivitiesList data={datos_fr} lang="fr" />
                <LoadMore lang="fr" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(RoamingActivities);